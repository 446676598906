import React from 'react';
import styles from './DayTitles.module.css';

const DayTitles = () => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Weekly Totals"];
    
    return (
      <div className={styles.dayTitles}>
        {daysOfWeek.map(day => (
          <div key={day} className={styles.dayTitle}>{day}</div>
        ))}
      </div>
    );
  };
  export default DayTitles;
