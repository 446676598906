import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import './Dashboard.css';
import { fetchQueryGraphThree, fetchQueryGraphOne2 } from './queries';
import { useUser } from "@clerk/clerk-react";

const GraphOne = () => {
  const { user } = useUser();
  const [weeklyDocsCount, setWeeklyDocsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [labels, setLabels] = useState([]);
  const [newlabels, setNewLabels] = useState([]);
  const [monthLeadsCount, setMonthLeadsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0]); // Default values
  const [isLoading, setIsLoading] = useState(false);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  //get start and end date for each week, starting today, going back 8 weeks
  const getDateRanges = () => {
    const rangesWeek = [];
    let currentDate = new Date();
    currentDate = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())); // Move to start of this week
  
    for (let i = 0; i < 8; i++) {
      const endOfWeek = new Date(currentDate);
      const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - 6)); // Move back to the start of the week
      rangesWeek.unshift({
        start: startOfWeek.toISOString().split('T')[0], // Format as YYYY-MM-DD
        end: endOfWeek.toISOString().split('T')[0]
      });
      currentDate = new Date(startOfWeek.setDate(startOfWeek.getDate() - 1)); // Move to the day before the start of last week
    }
    return rangesWeek;
  };

  //get start and end date for each month, starting today, going back 8 months
  const getMonthRanges = () => {
    const rangesMonth = [];
    let currentDate = new Date();  // Start from today
  
    for (let i = 0; i < 8; i++) {
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // First day of the current month
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0); // Last day of the current month
  
      rangesMonth.unshift({
        start: startOfMonth.toISOString().split('T')[0], // Format as YYYY-MM-DD
        end: endOfMonth.toISOString().split('T')[0]
      });
  
      currentDate = new Date(startOfMonth.setDate(startOfMonth.getDate() - 1)); // Move to the last day of the previous month
    }
  
    return rangesMonth;
  };

  // takes the month names and overlays into an array based on month ranges
  const transformDateLabelsToMonths = (labels) => {
    return labels.map(label => {
      const date = new Date(`${label}T00:00:00Z`);
      const monthIndex = date.getUTCMonth(); // Use getUTCMonth to avoid timezone issues
      return monthNames[monthIndex];  // Map the month index to month name
    });
  };
  
  // FIRST USE EFFECT FUNCTION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dateRanges = getMonthRanges();
      const labelsTemp = dateRanges.map(range => `${range.start}`);
      const countsJobs = [];
      const countsLeads = [];
  
      for (const range of dateRanges) {
        try {
          const query1 = await fetchQueryGraphThree(user.id, range.start, range.end);
          const response1 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query1, {
            headers: { 'Content-Type': 'application/json' }
          });
          countsJobs.push(response1.data.organization.jobs.nodes.length);
  
          const query2 = await fetchQueryGraphOne2(user.id, range.start, range.end);
          const response2 = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query2, {
            headers: { 'Content-Type': 'application/json' }
          });
          countsLeads.push(response2.data.organization.accounts.nodes.length);
        } catch (error) {
          console.error('Error fetching documents:', error);
          countsJobs.push(0); // Default value in case of error
          countsLeads.push(0); // Default value in case of error
        }
      }
  
      setLabels(labelsTemp);
      setWeeklyDocsCount(countsJobs);
      setMonthLeadsCount(countsLeads);
      setIsLoading(false);
    };
  
    if (user) {
      fetchData();
    }
  }, [user]);
  
  // Use an effect to transform labels once they are set
  useEffect(() => {
    const newLabels = transformDateLabelsToMonths(labels);
    setNewLabels(newLabels);
  }, [labels]); // Dependency on labels ensures transformation occurs after labels are updated
  

  const monthlyDiff = ( a, b ) => {
    const diff = (((a - b)/b)*100);
    const diff2 = diff.toPrecision(2);
    return diff2;
  };

  const totalDocs = () => {
    let total = 0;
    return total;
  };
  const createData = (labels, uvData, pvData) => {
    return labels.map((label, index) => ({
      name: label,
      jobs: uvData[index],
      leads: pvData[index]
    }));
  };
  
  const data1 = createData(newlabels, weeklyDocsCount, monthLeadsCount);
  
  const sumWeeklyDocsCount = (data) => {
    return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };
  
  const totalDocsCount = sumWeeklyDocsCount(weeklyDocsCount);

  
  return (
    <div className='widgetRow'>
        <div className='widgetStack'>
        <div className='dashwidget'>
            <div className='widgetNumber'>{monthLeadsCount[7]}</div><hr />
            <div className='widgetTitle'>New Leads</div>
            <div className='widgetSubTitle'>this month</div><hr />
            <div className='widgetSubTitle'>{monthlyDiff(monthLeadsCount[7], monthLeadsCount[6])}% to last month</div>
        </div>
        <div className='dashwidget'>
            <div className='widgetNumber'>{weeklyDocsCount[7]}</div><hr />
            <div className='widgetTitle'>Accepted Jobs</div>
            <div className='widgetSubTitle'>this month</div><hr />
            <div className='widgetSubTitle'>{monthlyDiff(weeklyDocsCount[7], weeklyDocsCount[6])}% to last month</div>
        </div>
        <div className='dashwidget'>
        <div className='widgetNumber'>{totalDocsCount}</div><hr />
            <div className='widgetTitle'>Accepted Jobs</div>
            <div className='widgetSubTitle'>this year</div><hr />
        </div>
        </div>
        <div className='dashwidget' style={{ gridColumn: 'span 3' }}>
        <ResponsiveContainer width="100%" height="100%" initialDimension={{ width: 520, height: 400 }}>
          <LineChart width={400} height={400} data={data1} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
          
            <Line type="monotone" dataKey="jobs" stroke="#8884d8" />
            <Line type="monotone" dataKey="leads" stroke="#82ca9d" />
            <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
        </div>
    </div>
  );
}

export default GraphOne;