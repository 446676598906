// Week.js
import React from 'react';
import Day from './Day';
import styles from './Week.module.css';

function numberWithCommas(x) {
  let x2 = x.toFixed(2);
  return x2.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const Week = ({ days, total }) => {
  const displayTotal = numberWithCommas(total); //format with commas
  return (
    <div className={styles.week}>
      {days}
      <div className={styles.bigpaydiv} >
        {displayTotal ? `$${displayTotal}` : '$0.00'} {/* Display the total if available */}
      </div>
    </div>
  );
};

export default Week;
