import React from 'react';
import { createRoot } from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = createRoot(document.getElementById('root'));

if (!clerkPubKey) {
  console.error("Missing Publishable Key");
  root.render(<div>Error: Clerk Publishable Key is missing</div>);
} else {
  root.render(
    <React.StrictMode>
      <ClerkProvider publishableKey={clerkPubKey}>
        <App />
      </ClerkProvider>
    </React.StrictMode>
  );
}
