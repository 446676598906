import React from 'react';

const Forms = () => {
  return (
    <div>
      <h1>Advanced Forms</h1>
      <h2>Coming Soon</h2>
    </div>
  );
};

export default Forms;
