import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

// Function to fetch grantKey and orgId from Firestore
const fetchGrantKeyAndOrgId = async (userId) => {
  const userDocRef = doc(db, "users", userId);
  try {
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      return { 
        grantKey: data.jtgrantkey, 
        orgId: data.jtorgid, 
        cfName: data.graphTwoSettingOne || 'Lead Source',
        calendarTaskType: data.calendarTaskType || 'Pay Phase' // Add calendar task type
      };
    } else {
      console.log("No such document!");
      return { 
        grantKey: 'defaultGrantKey', 
        orgId: 'defaultOrgId', 
        cfName: 'Lead Source',
        calendarTaskType: 'Pay Phase'
      };
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return { 
      grantKey: 'defaultGrantKey', 
      orgId: 'defaultOrgId', 
      cfName: 'Lead Source',
      calendarTaskType: 'Pay Phase'
    };
  }
};

export const fetchTasksQuery = async (userId, startDate, endDate) => {
    const { grantKey, orgId, calendarTaskType } = await fetchGrantKeyAndOrgId(userId);
    return {

    "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "tasks": {
          "$": {
            "where": {
              "and": [
                [["taskType", "name"], calendarTaskType],
                ["startDate", ">=", startDate],
                ["startDate", "<=", endDate]
              ]
            },
            "sortBy": [
              {
                "field": "startDate"
              }
            ],
            "size": 50,
          },
          "nodes": {
            "id": {},
            "name": {},
            "startDate": {},
            "description": {}
          }
        }
      }
    }
  }
  };

  export const fetchQueryGraphOne = async (userId, startDate, endDate) => {
    const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
    return {

    "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "documents": {
      "$": {
        "page": "",
        "where": {
          "and": [
            [
              "type",
              "customerOrder"
            ],
            [
              "status",
              "approved"
            ],
            [
              "issueDate",
              ">=",
              startDate
            ],
            [
              "issueDate",
              "<=",
              endDate
            ]
          ]
        },
        "sortBy": [
          {
            "field": "price",
            "order": "desc"
          }
        ],
        "size": 20
      },
      "nodes": {
        "id": {},
        "status": {},
        "issueDate": {},
        "cost": {},
        "price": {},
        "tax": {},
        "name": {},
        "number": {},
        "job": {
          "id": {},
          "name": {},
          "number": {}
        }
      },
      "nextPage": {},
      "previousPage": {}
    }
  }
  }
  }
};
  
export const fetchQueryGraphOne2 = async (userId, startDate, endDate) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {

   "query": {
      "$": { "grantKey": grantKey }, // Include the grant key in the query object
      "organization": {
    "$": {
      "id": orgId
    },
    "id": {},
    "accounts": {
      "$": {
        "where": {
          "and": [
            [
              "type",
              "=",
              "customer"
            ],
            [
              "createdAt",
              ">=",
              startDate
            ],
            [
              "createdAt",
              "<=",
              endDate
            ]
          ]
        },
        "size": 50
      },
      "nodes": {
        "id": {},
        "name": {},
        "type": {},
        "createdAt": {}
      }
    }
  }
  }
  }
};

// Now update fetchQueryGraphTwo to use cfName
export const fetchQueryGraphTwo = async (userId, startDate, endDate) => {
  const { grantKey, orgId, cfName } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": {
          "id": orgId
        },
        "id": {},
        "customFields": {
          "$": {
            "where":[
              "name",
              "=",
              cfName 
            ]
          },
          "nodes": {
            "id": {},
            "name": {},
            "targetType": {},
            "customFieldValues": {
              "nodes": {
                "id": {},
                "value": {},
                "createdAt": {}
              },
              "$": {
                "where": {
                  "and": [
                    ["createdAt", ">=", startDate],
                    ["createdAt", "<=", endDate]
                  ]
                }
              }
            }
          }
        }
      }
    }
  };
};

// query for approved jobs from Casey
export const fetchQueryGraphThree = async (userId, startDate, endDate) => {
  const { grantKey, orgId } = await fetchGrantKeyAndOrgId(userId);
  return {
    "query": {
      "$": { "grantKey": grantKey },
      "organization": {
        "$": {
          "id": orgId
        },
    "id": {},
    "jobs": {
      "$": {
        "with": {
          "approvedCustomerOrders": {
            "_": "documents",
            "$": {
              "where": {
                "and": [
                  [
                    "type",
                    "customerOrder"
                  ],
                  [
                    "status",
                    "approved"
                  ],
                  [
                    "createdAt",
                    ">=",
                    startDate
                  ],
                  [
                    "createdAt",
                    "<=",
                    endDate
                  ]
                ]
              }
            }
          }
        },
        "size": 30,
        "where": {
          "and": [
            [
              "closedOn",
              null
            ],
            [
              [
                "approvedCustomerOrders",
                "count"
              ],
              ">",
              0
            ]
          ]
        }
      },
      "nodes": {
        "id": {},
        "name": {}
      }
    }
  }
}
  }
};