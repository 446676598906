import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCPsTbvqc_BerSlKp_EYpBBDcxcFym64Y",
  authDomain: "jt-supercharged-db.firebaseapp.com",
  projectId: "jt-supercharged-db",
  storageBucket: "jt-supercharged-db.appspot.com",
  messagingSenderId: "549580907389",
  appId: "1:549580907389:web:bfe4d86ae8af1738baa214",
  measurementId: "G-MWNM1Q6ZHH"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };