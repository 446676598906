import React from 'react';

const Inventory = () => {
  return (
    <div>
      <h1>Inventory</h1>
      <h2>Coming Soon</h2>
    </div>
  );
};

export default Inventory;
