import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Day from './Day';
import Week from './Week';
import DayTitles from './DayTitles';
import styles from './Calendar.module.css';
import { fetchTasksQuery } from './queries';
import { useUser } from "@clerk/clerk-react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const Calendar = () => {
  const { user } = useUser();
  const [date, setDate] = useState(new Date());
  const [tasksByDate, setTasksByDate] = useState({});
  const [weeklyTotals, setWeeklyTotals] = useState({});
  const [weeks, setWeeks] = useState([]);
  const [monthTotal, setMonthTotal] = useState(0);
  const [grantKey, setGrantKey] = useState('Default Grant Key');
  const [orgId, setOrgId] = useState('Default Organization');

  const year = date.getFullYear();
  const month = date.getMonth();
  const monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  const monthName = monthNames[month];

  const startDate = React.useMemo(() => {
    const start = new Date(year, month, 1);
    while (start.getDay() !== 0) {
      start.setDate(start.getDate() - 1);
    }
    return start;
  }, [year, month]);

  const endDate = React.useMemo(() => {
    const end = new Date(year, month + 1, 0);
    while (end.getDay() !== 6) {
      end.setDate(end.getDate() + 1);
    }
    return end;
  }, [year, month]);

  const getWeekKey = (date) => {
    const copyDate = new Date(date); // Create a new Date instance to avoid mutating the original date
    copyDate.setDate(copyDate.getDate() - copyDate.getDay()); // Adjust to the nearest previous Sunday
    return copyDate.toISOString().split('T')[0];
  };

  const fetchUserData = async (userId) => {
    const userDocRef = doc(db, "users", userId); // Use Clerk unique user ID
    try {
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        setGrantKey(data.jtgrantkey);
        setOrgId(data.jtorgid);
      } else {
        console.log("No such document!");
        // Set default values if document does not exist
        setGrantKey('Default Grant Key');
        setOrgId('Default Organization');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Set default values in case of error
      setGrantKey('Default Grant Key');
      setOrgId('Default Organization');
    }
  };

  // FIRST USE EFFECT FUNCTION
  useEffect(() => {
    const fetchData = async () => {
      let weekStartDate = new Date(startDate);
      const query = await fetchTasksQuery(user.id, startDate, endDate);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
        headers: { 'Content-Type': 'application/json' }
      });
      const tasks = response.data.organization.tasks.nodes;
      const newGroupedTasks = {};
      const newWeeklyTotals = {};

      tasks.forEach(task => {
        const dateKey = task.startDate.split('T')[0];
        newGroupedTasks[dateKey] = newGroupedTasks[dateKey] || [];
        newGroupedTasks[dateKey].push(task);

        const taskDate = new Date(task.startDate);
        taskDate.setDate(taskDate.getDate() - taskDate.getDay());
        const weekKey = getWeekKey(new Date(task.startDate)-1);
        newWeeklyTotals[weekKey] = (newWeeklyTotals[weekKey] || 0) + parseFloat(task.description) || 0;
      });

      setTasksByDate(newGroupedTasks);
      setWeeklyTotals(newWeeklyTotals);
    };

    if (user) {
      fetchUserData(user.id).then(fetchData);
    }
  }, [user, startDate, endDate]);

  // USE EFFECT FUNCTION FOR MONTHLY TOTALS
  useEffect(() => {
    // Calculate the total of all weekly totals
    const total = Object.values(weeklyTotals).reduce((acc, curr) => acc + curr, 0);
    setMonthTotal(total); // Set the calculated total to monthTotal state
  }, [weeklyTotals]);

  //SECOND USE EFFECT FUNCTION
  useEffect(() => {
    let tempDays = [];
    let tempWeeks = [];
    let currentDay = new Date(startDate);
    let weekStartDate = new Date(startDate);

    while (currentDay <= endDate) {
      const dayKey = currentDay.toISOString().split('T')[0];
      const isCurrentMonth = currentDay.getMonth() === month;

      tempDays.push(
        <Day key={dayKey}
             day={isCurrentMonth ? currentDay.getDate() : null}
             year={year}
             month={currentDay.getMonth()}
             fromOtherMonth={!isCurrentMonth}
             tasks={tasksByDate[dayKey]}
        />
      );

      if (tempDays.length === 7 || currentDay.getTime() === endDate.getTime()) {
        const weekKey = getWeekKey(weekStartDate);
        tempWeeks.push(
          <Week key={weekKey} days={tempDays} total={weeklyTotals[weekKey] || 0} />
        );
        tempDays = [];
        weekStartDate = new Date(currentDay.getTime() + 86400000);
      }

      currentDay.setDate(currentDay.getDate() + 1);
    }

    setWeeks(tempWeeks);
  }, [tasksByDate, weeklyTotals, startDate, endDate]);

  // Previous and Next month functions
  const previousMonth = () => {
    setDate(new Date(year, month - 1, 1));
  };

  const nextMonth = () => {
    setDate(new Date(year, month + 1, 1));
  };

  // function to format number with commas
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  const monthTotalFixed = monthTotal.toFixed(2);
  const monthTotalFixed2 = numberWithCommas(monthTotalFixed);

  return (
    <div className={styles.calendar}>
      <div className={styles.monthTitle}>
        <button onClick={previousMonth} className="button button-secondary">&lt; Prev</button>
        {monthName} {year}
        <button onClick={nextMonth} className="button button-secondary">Next &gt;</button>
      </div>
      <div className={styles.monthTotal}>Monthly Total: ${monthTotalFixed2}</div>
      <DayTitles />
      {weeks}
    </div>
  );
};

export default Calendar;
