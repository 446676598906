// Day.js
import React from 'react';
import styles from './Day.module.css';

const Day = ({ day, year, month, fromOtherMonth, tasks, total }) => {
  // Calculate today's date for comparison
  const today = new Date();
  const isToday = day === today.getDate() && month === today.getMonth() && year === today.getFullYear();

  // Correctly add the 'today' class when it's the current day
  const dayClass = `${styles.day} ${isToday ? styles.today : ''} ${fromOtherMonth ? styles.otherMonth : ''}`;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

  return (
    <div className={dayClass}>
      {day}
      {tasks && tasks.map((task, index) => {
        // Convert description to a number, and handle NaN by displaying 0 or some default value
        const taskValue = parseFloat(task.description);
        const displayValue = !isNaN(taskValue) ? taskValue.toFixed(2) : 'N/A'; // Format or handle non-numbers
        const displayValue2 = numberWithCommas(displayValue); //format with commas 

        return (
          <div key={index} className={styles.paydiv}>
            <p>{task.name}</p>
            <p>${displayValue2}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Day;