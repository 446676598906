import React from 'react';
import { SignUpButton, SignedIn, SignedOut } from '@clerk/clerk-react';
import '../App.css';

function About() {

  return (
    <div className="about-page">
      <h1>JT Supercharged</h1>
      <p>This is a companion app for JobTread, the leading construction project management software.</p>
      <p>Data is pulled from <u>your</u> JobTread account to visualize and interact in new ways.</p>
      <SignedOut>
        <SignUpButton mode="modal">
            <button className="button button-secondary">Sign Up</button>
        </SignUpButton>   
      </SignedOut>
      <h2>Current Features</h2>
      <div className="about-item">
        <h2>Dashboard</h2>
        <p>Vizualize your data.</p>
        <img className="about-image" src={require('../assets/barchart.png')} alt="Dashboard" />
      </div>
      <div className="about-item">    
        <h2>Cashflow Calendar</h2>
        <p>View your cashflow daily, weekly, monthly.</p>
        <img className="about-image" src={require('../assets/paycal.png')} alt="Cashflow Calendar" />
      </div>
      <div className="about-item">
        <h2>Library</h2>
        <p>Templates for cost groups, tasks, todos - created and shared by users.</p>
        <img className="about-image" src={require('../assets/library.png')} alt="Library" />
      </div>
      <div className="about-item">
        <h2>Inventory</h2>
        <p>Basic inventory management integrated into JobTread.</p>
        <img className="about-image" src={require('../assets/inventory.png')} alt="Inventory" />
      </div>
      <div className="about-item">
        <h2>Advanced Forms</h2>
        <p>Custom forms to gather information in ways you never thought possible.</p>
        <img className="about-image" src={require('../assets/forms.png')} alt="Advanced Forms" />
      </div>
      <SignedOut>
    <h2>Get Started</h2>
      <p>Sign up today to experience the full power of our application and take your project management to the next level!</p>
      <SignUpButton mode="modal">
        <button className="button button-secondary">Sign Up</button>
      </SignUpButton>   
      </SignedOut>
    </div>
  );
}

export default About;
