import React from 'react';
import { useAuth, SignedIn, SignedOut, UserButton, SignInButton, SignUpButton } from '@clerk/clerk-react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Calendar from './components/Calendar';
import TopNav from './components/TopNav';
import SidebarNav from './components/SidebarNav';
import SignInPage from './components/SignInPage';
import UserProfile from './components/FirebaseTest';
import Forms from './components/Forms';
import Inventory from './components/Inventory';
import Library from './components/Library';
import LibraryItem from './components/LibraryItem';
import LibraryUpload from './components/LibraryUpload';
import About from './components/About'; 
import './App.css';

function App() {
  
  

  return (
    <Router>
      <div className='App'>
        <TopNav />
        <div className='mainContent'> 
          <SidebarNav />
          <div className='pageContentWrapper'>
          <div className='pageContent'>
          
            <SignedIn>
            <Routes> 
              <Route path="/" element={<About />} /> 
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/library" element={<Library />} />
                <Route path="/library/:id" element={<LibraryItem />} />
                <Route path="/upload" element={<LibraryUpload />} />
                <Route path="/settings" element={<UserProfile />} />
                <Route path="/forms" element={<Forms />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/sign-in" element={<SignInPage />} />
            </Routes>
            </SignedIn>
              <SignedOut>
                <Routes>  
                <Route path="/" element={<About />} /> 
                <Route path="*" element={<SignInPage />} />
                </Routes>
              </SignedOut>
          </div>
        </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
