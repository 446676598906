import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { signInAnonymously } from 'firebase/auth';
import { db, storage, auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const LibraryUpload = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    const authenticateAnonymously = async () => {
      try {
        await signInAnonymously(auth);
      } catch (error) {
        console.error("Error during anonymous authentication:", error);
        setError('Failed to authenticate. Please try again.');
      }
    };

    authenticateAnonymously();
  }, []);

  const handleCsvFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].name.endsWith('.csv')) {
      setCsvFile(e.target.files[0]);
      setError('');
    } else {
      setCsvFile(null);
      setError('Please select a valid CSV file.');
    }
  };

  const handleImageFileChange = (e) => {
    if (e.target.files[0] && e.target.files[0].type.startsWith('image/')) {
      setImageFile(e.target.files[0]);
      setError('');
    } else {
      setImageFile(null);
      setError('Please select a valid image file.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!csvFile) {
      setError('Please select a CSV file to upload');
      return;
    }

    setUploading(true);
    setError('');

    try {
      // Upload CSV file
      const csvStorageRef = ref(storage, `library/csv/${Date.now()}_${csvFile.name}`);
      await uploadBytes(csvStorageRef, csvFile);
      const csvDownloadURL = await getDownloadURL(csvStorageRef);

      let imageDownloadURL = null;
      if (imageFile) {
        // Upload image file
        const imageStorageRef = ref(storage, `library/images/${Date.now()}_${imageFile.name}`);
        await uploadBytes(imageStorageRef, imageFile);
        imageDownloadURL = await getDownloadURL(imageStorageRef);
      }

      // Add document to Firestore
      const docRef = await addDoc(collection(db, 'library'), {
        name,
        desc: description,
        author: user ? user.fullName : 'Anonymous', // Add the author field
        csvFileURL: csvDownloadURL,
        imageURL: imageDownloadURL,
        rating: 0,
        downloads: 0,
        csvFileName: csvFile.name,
        imageFileName: imageFile ? imageFile.name : null,
        createdAt: new Date()
      });

      setUploading(false);
      // Navigate to the newly created item's page
      navigate(`/library/${docRef.id}`);
    } catch (error) {
      console.error("Error uploading files or adding document:", error);
      setUploading(false);
      setError('An error occurred while uploading. Please try again.');
    }
  };

  return (
    <div>
      <Link to="/library" style={{ textDecoration: 'none', color: 'blue' }}>← Back to Library</Link>
      <h2>Upload New Library Item</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="csvFile">Template File (CSV only):</label>
          <input
            type="file"
            id="csvFile"
            accept=".csv"
            onChange={handleCsvFileChange}
            required
          />
        </div>
        <div>
          <label htmlFor="imageFile">Image (optional):</label>
          <input
            type="file"
            id="imageFile"
            accept="image/*"
            onChange={handleImageFileChange}
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit" disabled={uploading || !csvFile}>
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
    </div>
  );
};

export default LibraryUpload;
