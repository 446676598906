import React from 'react';
import { SignIn } from '@clerk/clerk-react';

function SignInPage() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '50px',
    }}>
      <h1>Sign In</h1>
      <SignIn />
    </div>
  );
}

export default SignInPage;
