import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { signInAnonymously } from 'firebase/auth';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Adjust this import based on your Firebase setup
import '../App.css';

// Star rating component
const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  
    return (
      <span style={{ color: 'gold' }}>
        {'★'.repeat(fullStars)}
        {hasHalfStar ? '½' : ''}
        {'☆'.repeat(emptyStars)}
      </span>
    );
  };

// Button component
const Button = ({ children, color, onClick }) => (
    <button
      onClick={onClick}
      className="button button-secondary"
    >
      {children}
    </button>
  );

// Download icon component
const DownloadIcon = () => (
    <span style={{ fontSize: '1.2em', marginRight: '5px' }}>⬇️</span>
  );

const LibraryItem = () => {
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        // Attempt anonymous sign-in
        await signInAnonymously(auth);

        const docRef = doc(db, 'library', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setItem({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('No such document exists');
        }
      } catch (err) {
        console.error("Error fetching document:", err);
        setError('Error fetching document. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItem();
  }, [id]);

  const handleDownload = async () => {
    try {
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = item.csvFileURL;
      link.download = item.name || 'download'; // Use item name as filename, or 'download' if not available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Update the download counter in the database
      const docRef = doc(db, 'library', id);
      await updateDoc(docRef, {
        downloads: increment(1)
      });
      
      // Update the local state
      setItem(prevItem => ({
        ...prevItem,
        downloads: prevItem.downloads + 1
      }));
    } catch (error) {
      console.error("Error downloading file or updating download count:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    return <div>No item found</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Link to="/library" style={{ textDecoration: 'none', color: 'blue' }}>← Back to Library</Link>
      <h1>{item.name}</h1>
      <p>Uploaded by: {item.author}</p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>        
        
        <Button onClick={handleDownload}>
          Download File
        </Button>
      <span style={{ marginLeft: '20px' }}>
          <DownloadIcon />{item.downloads} downloads
        </span>
      
      {/* Commented out star rating section for future reference
      <span style={{ marginLeft: '20px' }}>
            <StarRating rating={item.rating} />
        <span style={{ marginLeft: '5px' }}>({item.rating})</span>
      </span>
      */}
      </div>
      <p>{item.desc}</p>
      {item.imageURL && (
        <img src={item.imageURL} alt={item.name} style={{ maxWidth: '100%', marginBottom: '20px' }} />
      )}
      
      
    </div>
  );
};

export default LibraryItem;