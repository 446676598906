import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import GraphOne from './GraphOne';
import GraphTwo from './GraphTwo';
import './Dashboard.css';

function Dashboard() {
    const { isLoaded, userId } = useAuth();
    
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (!userId) {
        // Use navigate function directly instead of returning a component
        return <Navigate to="/sign-in" replace={true} />;
    }

    return (
        <div>
            <div className='dashHeader'>
                <div className='dashTitle'>Dashboard</div>
            </div>
            <GraphOne />
            <GraphTwo />   
        </div>
    );
}

export default Dashboard;
