import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import { fetchQueryGraphTwo } from './queries';
import { useUser } from "@clerk/clerk-react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid
} from 'recharts';

const GraphTwo = () => {
  const { user } = useUser();
  const [labels, setLabels] = useState([]);
  const [newLabels, setNewLabels] = useState([]);
  const [monthLeadsCount, setMonthLeadsCount] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [highestSum, setHighestSum] = useState([]);
  const [sums, setSums] = useState([]);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  //get start and end date for each month, starting today, going back 8 months
  const getMonthRanges = () => {
    const rangesMonth = [];
    let currentDate = new Date();  // Start from today
  
    for (let i = 0; i < 8; i++) {
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); // First day of the current month
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0); // Last day of the current month
  
      rangesMonth.unshift({
        start: startOfMonth.toISOString().split('T')[0], // Format as YYYY-MM-DD
        end: endOfMonth.toISOString().split('T')[0]
      });
  
      currentDate = new Date(startOfMonth.setDate(startOfMonth.getDate() - 1)); // Move to the last day of the previous month
    }
  
    return rangesMonth;
  };

  // takes the month names and overlays into an array based on month ranges
  const transformDateLabelsToMonths = (labels) => {
    return labels.map(label => {
      const date = new Date(`${label}T00:00:00Z`);
      const monthIndex = date.getUTCMonth(); // Use getUTCMonth to avoid timezone issues
      return monthNames[monthIndex];  // Map the month index to month name
    });
  };
  
  // FIRST USE EFFECT FUNCTION
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const dateRanges = getMonthRanges();
      const labelsTemp = dateRanges.map(range => range.start);
      let leadSourceCountsPerMonth = {};  // Initialize here within useEffect

      for (const range of dateRanges) {
        try {
          const query = await fetchQueryGraphTwo(user.id, range.start, range.end);
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, query, {
            headers: { 'Content-Type': 'application/json' }
          });
  
          if (response.data.organization.customFields.nodes.length > 0) {
            const customFieldsNodes = response.data.organization.customFields.nodes[0];
            const customFieldValues = customFieldsNodes.customFieldValues.nodes;
            countCustomFieldValuesMap(customFieldValues, range.start, leadSourceCountsPerMonth);
          }
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      }
      //formats the object correctly for bar chart
      const formattedData = transformToChartData(leadSourceCountsPerMonth, newLabels);
      
      setLabels(labelsTemp);
      setIsLoading(false);
      setFormattedData(formattedData);
    };
  
    if (user) {
      fetchData();
    }
  }, [user]);

  const countCustomFieldValuesMap = (customFieldValues, monthLabel, leadSourceCountsPerMonth) => {
    // Initialize the month entry if it doesn't exist
    leadSourceCountsPerMonth[monthLabel] = leadSourceCountsPerMonth[monthLabel] || {};
  
    customFieldValues.forEach(item => {
      const fieldValue = item.value; // Assuming 'value' holds the lead source name
      if (fieldValue) {
        if (leadSourceCountsPerMonth[monthLabel][fieldValue]) {
          leadSourceCountsPerMonth[monthLabel][fieldValue] += 1;
        } else {
          leadSourceCountsPerMonth[monthLabel][fieldValue] = 1;
        }
      }
    });
  };
  
  // Use an effect to transform labels once they are set
  useEffect(() => {
    const newLabels = transformDateLabelsToMonths(labels);
    setNewLabels(newLabels);
  }, [labels]); // Dependency on labels ensures transformation occurs after labels are updated
  
  //function to transform leadSourceCountsPerMonth into correct format for the bar charts
const transformToChartData = (data, monthLabels) => {
  const chartData = [];
  // Using monthLabels to ensure alignment
  Object.keys(data).forEach((monthKey, index) => {
    let monthName = monthLabels[index];  // Getting month name from the transformed labels
    let entry = { month: monthName || monthKey };  // Use month name, fallback to monthKey if undefined
    console.log(monthLabels);
    Object.entries(data[monthKey]).forEach(([source, count]) => {
      const formattedSource = source.replace(/ \(.*?\)/, '');  // Simplify source name
      entry[formattedSource] = count;  // Add source count to the entry
    });

    chartData.push(entry);  // Add the entry to chart data array
  });

  return chartData;
};

useEffect(() => {
  if (formattedData.length > 0) {
    const newKeys = formattedData.reduce((allKeys, item) => {
        Object.keys(item).forEach(key => {
            if (key !== 'month' && !allKeys.includes(key)) {
                allKeys.push(key);
            }
        });
        return allKeys;
    }, []);
    setKeys(newKeys);
}

  // Object to store the sum of each key
  const sums = {};

  // Traverse each item in the data array
  formattedData.forEach(item => {
      Object.keys(item).forEach(key => {
          if (key !== "month") {  // Ignore the "month" key
              sums[key] = (sums[key] || 0) + item[key];  // Sum the values for each key
          }
      });
  });

  // Find the key with the highest and lowest total
  let highestSum = { key: null, value: 0 };
  let lowestSum = { key: null, value: Infinity };

  Object.entries(sums).forEach(([key, value]) => {
      if (value > highestSum.value) {
          highestSum = { key, value };
      }
      if (value < lowestSum.value) {
          lowestSum = { key, value };
      }
  });
  setHighestSum(highestSum);
  setSums(sums);

}, [formattedData]);

//generate total for all lead sources
const totalSum = Object.values(sums).reduce((acc, value) => acc + value, 0);

// Generate a color for each bar dynamically (or you could define a fixed color map)
const colors = ['#F94144', '#4D908E', '#F8961E', '#90BE6D', '#F9C74F', '90BE6D', '43AA8B', '4D908E', '577590', '277DA1'];
  
  return (
    <div className='widgetRow'>
        <div className='widgetStack'>
          <div className='dashwidget'>
              <div className='widgetNumber'>{totalSum}</div><hr />
              <div className='widgetTitle'>New Leads</div>
              <div className='widgetSubTitle'>this year</div><hr />
          </div>
          <div className='dashwidget'>
            <div className='widgetNumber'>{highestSum.value}</div><hr />
              <div className='widgetTitle'>{highestSum.key}</div><hr />
              <div className='widgetSubTitle'>Biggest Source</div>
          </div>
          <div className='dashwidget'>
              <div className='widgetTitle'>All Sources</div><hr />
              <div className='widgetSubTitle'>
                {Object.entries(sums)
                  .sort((a, b) => b[1] - a[1])  // Sorting by value in descending order
                  .map(([key, value], index) => (
                    <p key={index}>{key}: {value}</p>
                  ))}
              </div>
          </div>
        </div>
        <div className='dashwidget' style={{ gridColumn: 'span 3' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {keys.map((key, index) => (
              <Bar key={key} dataKey={key} stackId="a" fill={colors[index % colors.length]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
        </div>
    </div>
  );
}


  export default GraphTwo;