import React from 'react';
import styles from './TopNav.module.css';
import { SignedIn, UserButton, SignedOut, SignInButton, SignUpButton } from '@clerk/clerk-react';

function TopNav() {
  return (
    <div className={styles.topNav}>
        <div className={styles.logoContainer}>JT Supercharged</div>
      <div className={styles.userInfo}>
        {/* Placeholder for user info and notifications */}
        <span>
          <SignedIn>
            <UserButton />
          </SignedIn>
          <SignedOut>
          <SignUpButton mode="modal">
            <button className="button button-secondary">Sign Up</button>
          </SignUpButton>   
          <SignInButton mode="modal">
            <button className="button button-primary">Sign In</button>
          </SignInButton>   
          </SignedOut>
        </span>
      </div>
    </div>
  );
};

export default TopNav;
